body{ background: #000; color: #FFF; }
a{ outline: none; color: #fff; }
.table,.th, .td, .thead, .tbody, .tfoot, .pagination{ color: #fff !important; }
button, .btn{ box-shadow: none !important; outline: none !important; }
.site-header .navbar{ background: #000; height: 90px; }
.site-header .logo{ height: 70px; }
.border-gray,.dashboard-content h2{ border-bottom: 1px solid #444 !important }
.lh-30{ line-height: 30px; }
.site-content{ margin-top: 90px; }
.site-footer{ text-align: center; color: #777; margin-bottom: 15px; }

.btn-logout, .btn-login, .btn-register{ margin-right: -24px !important; margin-left:30px; }
.heading-topright{ float: right; margin-top: -75px; }

.dashboard-navigation,.dashboard-navigation li{ margin: 0; padding: 0; list-style: none;  }
.dashboard-navigation li{ margin-bottom: 15px; }
.dashboard-navigation li a{ display: block; font-weight: bold; font-size: 18px; line-height: 20px; text-decoration: none; position: relative; padding:15px 10px 15px 65px; color: #fff; }
.dashboard-navigation li a.active{ color: #0d6efd; }
.dashboard-navigation li a .navicon{ position: absolute; left: -2px; text-align: center; margin-top: -25px; width: 50px; height: 50px; top:50%; }
.dashboard-navigation li a .navicon img{ max-height: 50px; }
.dashboard-content .main-box{ background-color: #222; border: 0px solid #262626; border-radius: 20px; }
.dashboard-content .main-box.blue{ background-color: #0d6efd; }
.dashboard-content h2{ margin:0 0 25px 0;  padding-bottom: 17px; font-size: 30px; line-height: 30px;}

.theme-mode button,.theme-mode button:hover{ border: none; padding-left: 10px; padding-right: 10px; background: #fff;background-color: #ccc; color: #000; }
.theme-mode button.Mui-selected,.theme-mode button.Mui-selected:hover{ color: #fff; background-color: #0d6efd; }

.thead{ background: #222; }
.td,.th{ border-bottom-color: #222 !important; }
.th{ padding: 9px 16px !important; }
.td{ padding: 6px 16px !important; }
.pagination > div{ width: 100%; }
.pagination > div *{ color: #aaa; }
.pagination p{ margin-bottom: 0; }

.loginBox .formField{ background: #ccc; color: #333; border-color:transparent; box-shadow: none;  }
.loginBox input,.loginBox label{ color: #444; font-weight: bold; font-size: 16px; }

.form-control.is-invalid, .was-validated .form-control:invalid { border-color:#cc0000; }
.form-control.is-invalid + label, .was-validated .form-control:invalid + label{  color: #cc0000; }

.registerBox{ max-width: 600px; margin: auto; }
.registerBox form{ background: #fff; }
.registerBox label{ color: #222; }
.registerBox input{ background: #eee; border:1px solid #ccc; }

.modal-header { border: 0; padding:11px 15px 13px; background:#0d6efd; border-bottom:1px solid #0057e6; }
.modal-header .modal-title{ color: #fff; font-size: 17px; font-weight: bold; line-height: 20px; text-transform: capitalize; }
.modal-header .btn-close{ background: none; position: relative;  opacity: 0.7; }
.modal-header .btn-close::after,.modal-header .btn-close::before{ content:" "; position:absolute; background: #fff; transform: rotate(45deg); }
.modal-header .btn-close::after{ width: 2px; height: 21px; top: 50%; margin-top: -9px;  }
.modal-header .btn-close::before{ width: 21px; height: 2px; margin-left: -10px; left: 50%; }
.modal-header .btn-close:hover { opacity: 0.9; }
.modal-body{ background: #333; }
.modal-footer{ padding: 8px 15px 8px; border: 0; background: #222; }
.modal-footer .btn{ padding: 7px 12px 11px; line-height: 16px; text-transform: capitalize; }

.light-theme{ background: #fff; color: #222; }
.light-theme .table,.light-theme .th,.light-theme .td, .light-theme .thead, .light-theme .tbody, .light-theme .tfoot, .pagination{ color: #222 !important; }
.light-theme a{ color: #0d6efd; }
.light-theme .site-header .navbar{ background: #fff; }
.light-theme .border-gray, .light-theme .dashboard-content h2{ border-bottom: 1px solid #ccc !important }
.light-theme .dashboard-navigation li a{ color: #222; }
.light-theme .dashboard-navigation li a.active{ color: #0d6efd; }
.light-theme .dashboard-content .main-box{ background-color: #f5f5f5; border: 1px solid #eee; }
.light-theme .theme-mode button{ background-color: #ccc; }
.light-theme .theme-mode button.Mui-selected{ color: #fff; background-color: #0d6efd; }

.light-theme .modal-footer,.light-theme .modal-body{ background: #fff; color: #222; }
.light-theme .modal-footer{ background: #eee; border-top:1px solid #e5e5e5; }

.light-theme .thead{ background: #e5e5e5;  }
.light-theme .td,.light-theme .th{ border-bottom-color: #e5e5e5 !important; }
.light-theme .pagination > div *{ color: #555; }

body:not(.light-theme) .modal-body .form-control { background: #999; border-color: #262626; color: #222; }